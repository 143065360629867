// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, getDocs, onSnapshot, deleteDoc, setDoc, getDoc, doc } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD8bjg2doJLZl6LHNkmtIWHAgSLbXSp7OY",
    authDomain: "versatile-d733a.firebaseapp.com",
    projectId: "versatile-d733a",
    storageBucket: "versatile-d733a.appspot.com",
    messagingSenderId: "916118233709",
    appId: "1:916118233709:web:adb0f58f9cde35265e5564",
    measurementId: "G-TH7BVNK77W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Export the Firestore instance and required Firestore functions
export { db, collection, addDoc, getDocs, onSnapshot, deleteDoc, setDoc, getDoc, doc };
