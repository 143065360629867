import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaStar, FaStarHalfAlt, FaRegStar, FaTrashAlt, FaHistory, FaAmazon, FaEbay } from 'react-icons/fa';
import { db, collection, getDocs, deleteDoc, setDoc, doc } from './firebase';
import { TbBrandWalmart  } from "react-icons/tb";
import './App.css';
import keys from './keys';
import 'tailwindcss/tailwind.css';
import SearchBar from './SearchBar';
import stringSimilarity from 'string-similarity';




const renderStars = (rating) => {
  const maxStars = 5;
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.25;
  const emptyStars = maxStars - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="flex items-center">
      <span className="text-sm mr-1">{rating.toFixed(1)}</span>
      {Array(fullStars).fill(<FaStar className="text-yellow-500 text-xl" />)}
      {halfStar && <FaStarHalfAlt className="text-yellow-500 text-xl" />}
      {Array(emptyStars).fill(<FaRegStar className="text-yellow-500 text-xl" />)}
    </div>
  );
};

const App = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [topItems, setTopItems] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [descriptions, setDescriptions] = useState({});
  const [typingItem, setTypingItem] = useState(null);
  const [showHistoryPopup, setShowHistoryPopup] = useState(false);

  useEffect(() => {
    const fetchSearchHistory = async () => {
      const historyCollection = collection(db, 'searchResults');
      const historySnapshot = await getDocs(historyCollection);
      const historyList = historySnapshot.docs.map(doc => doc.data().term);
      setSearchHistory(historyList);
    };

    fetchSearchHistory();
  }, []);

  useEffect(() => {
    const fetchDescriptions = async () => {
      for (const item of topItems) {
        if (!descriptions[item.name]) {
          const description = await generateDescription(item.name);
          setTypingItem(item.name); 
          simulateTyping(item.name, description);
        }
      }
    };

    if (topItems.length > 0) {
      fetchDescriptions();
    }
  }, [topItems]);

  const simulateTyping = (itemName, description) => {
    const typingSpeed = 10;
    let index = 0;
    const intervalId = setInterval(() => {
      setDescriptions(prevDescriptions => ({
        ...prevDescriptions,
        [itemName]: description.slice(0, index + 1)
      }));
      index++;
      if (index >= description.length) {
        clearInterval(intervalId);
      }
    }, typingSpeed);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(event.target.value);
      handleSearch();
    }
  };


  const parseItemDetails = (itemText) => {
    const parts = itemText.split('$');
    const name = parts[0].trim(); // item name
    const pro = parts[1].trim();  // pro
    const con = parts[2].trim();  // con
    const stars = parseFloat(parts[3].split('/')[0].trim()); // number of stars
  
    return { name, pro, con, stars };
  };

  const generateDescription = async (item) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Generate a product description for: ${item}. Full sentences only in 20-30 words please.` }
          ],
          max_tokens: 50,
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${keys.OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const description = response.data.choices[0].message.content;
      return description;
    } catch (err) {
      console.error('Error generating description from OpenAI:', err);
      return 'No description available';
    }
  };

  const saveSearchResults = async (term, items) => {
    try {
      const docRef = doc(db, 'searchResults', term);
      await setDoc(docRef, { term, items });
    } catch (err) {
      console.error('Error saving search results:', err);
    }
  };

  const fetchSavedResults = async (terms) => {
    try {
        const historyCollection = collection(db, 'searchResults');
        const historySnapshot = await getDocs(historyCollection);
        const historyItems = historySnapshot.docs.map(doc => doc.data());

        const results = historyItems.flatMap(item => {
            return terms.map(term => ({
                ...item,
                term,
                similarity: stringSimilarity.compareTwoStrings(term.toLowerCase(), item.term.toLowerCase())
            }));
        });

        const bestMatches = results.filter(result => result.similarity > 0.75);
        const groupedResults = bestMatches.reduce((acc, result) => {
            acc[result.term] = acc[result.term] || [];
            acc[result.term].push(result.items);
            return acc;
        }, {});

        // Return results grouped by term
        return groupedResults;
    } catch (err) {
        console.error('Error fetching saved results:', err);
        return null;
    }
  };


  
  const handleSearch = async () => {
    console.time('handleSearch');
    setLoading(true);
    setError('');
    setTopItems([]);
    setDescriptions({});
    setTypingItem(null);
  
    try {
      console.log('Search term:', searchTerm);
  
      // step 1: check AWS OpenSearch backend for synonyms
      //const synonymResponse = await axios.post('http://127.0.0.1:5000/api/search', { term: searchTerm });
      const synonymResponse = await axios.post('https://search.versatileai.net/api/search', { term: searchTerm });
      const synonyms = synonymResponse.data;
      console.log('Synonyms response:', synonyms);
  
      let termsToSearch = [searchTerm];
  
      // if synonyms are found, take the highest score's synonyms and add them to the search terms
      if (synonyms && typeof synonyms === 'object' && Object.keys(synonyms).length > 0) {
        if (!synonyms.message) {
          const highestScore = Math.max(...Object.keys(synonyms).map(Number));
          termsToSearch = [...synonyms[highestScore]];
        }
      } else {
        console.log('No synonyms found, proceeding to new search.');
      }
  
      // step 2: check Firebase for any of these terms
      console.log('Checking Firebase for terms:', termsToSearch);
      const firebaseResults = await fetchSavedResults(termsToSearch);
  
      // if firebase results are found, display them
      if (firebaseResults) {
        console.log('Firebase results found:', firebaseResults);        
        const firstKey = Object.keys(firebaseResults)[0];
        const results = firebaseResults[firstKey];

        if (results && results.length > 0) {
            const firstResult = results[0];
            const descriptionsMap = {
                [firstKey]: firstResult.reduce((acc, item) => {
                    acc[item.name] = item.description || 'No description available';
                    return acc;
                }, {})
            };

            setDescriptions(descriptionsMap);
            setTopItems(firstResult);
            setLoading(false);
            console.timeEnd('handleSearch');
            return;
        } else {
            console.log('No results found for the first key:', firstKey);
        }
      }

  
      // step 3: If no synonyms or Firebase results, fall back to OpenAI API
      console.log('Fetching data from OpenAI API...');
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o-mini',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            {
              role: 'user',
              content: `Give me the top 10 ${searchTerm} (shopping context) in a numbered list. 
                Give one BRIEF pro and one con for each. Find a star review for each. Full sentences only in 450 tokens. 
                Follow the format EXACTLY, the format has the name, pro, con, and stars (integer). Dont forget any of them. 
                Here is an example of the format I want: 
                Apple AirPods Pro$Active noise cancellation for immersive sound experience$Expensive price point$4.5/5
                FORMAT TEMPLATE: item name$pro$con$stars`,
              },
            ],
          max_tokens: 450,
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${keys.OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const text = response.data.choices[0].message.content;
      console.log('OpenAI GPT response:', text);
  
      const listItems = text.match(/(\d+\.\s[^\n]+)/g) || [];
      console.log('List items extracted from GPT response:', listItems);
  
      const parsedItems = await Promise.all(listItems.map(async (item, index) => {
        console.log(`Parsing item ${index + 1}:`, item);
        const details = parseItemDetails(item.replace(/^\d+\.\s/, ''));
        console.log('Parsed item details:', details);
        
        const description = await generateDescription(details.name);
  
        return { ...details, description };
      }));
  
      if (parsedItems.length > 0) {
        console.log('Parsed items:', parsedItems);
        setTopItems(parsedItems.slice(0, 10));
        await saveSearchResults(searchTerm, parsedItems.slice(0, 10));
        setSearchHistory(prevHistory => [...new Set([searchTerm, ...prevHistory])]);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data');
    } finally {
      setLoading(false);
      console.timeEnd('handleSearch');
    }
  };

  const handleHistoryClick = async (term) => {
    const savedResults = await fetchSavedResults(term);
    setTopItems(savedResults);

    const descriptionsMap = savedResults.reduce((acc, item) => {
      acc[item.name] = item.description || 'No description available';
      return acc;
    }, {});
    setDescriptions(descriptionsMap);
  };

  const handleDeleteHistory = async () => {
    try {
      const historyCollection = collection(db, 'searchResults');
      const historySnapshot = await getDocs(historyCollection);
      const deletePromises = historySnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
      setSearchHistory([]);
    } catch (err) {
      console.error('Error deleting history from Firestore:', err);
      setError('Error deleting history from Firestore');
    }
  };

  
  const handleHistoryButtonClick = () => {
    setShowHistoryPopup(true);
  };

  const handleClosePopup = () => {
    setShowHistoryPopup(false);
  };


  return (
    <div className="App min-h-screen flex flex-col items-center justify-center relative">
      <h1 className="text-3xl font-bold mb-4">Versatile Top 10</h1>
      <div className="flex items-center mb-4">
        <SearchBar 
          searchTerm={searchTerm} 
          onChange={handleChange} 
          onKeyPress={handleKeyPress} 
          handleSearch={handleSearch}
          setSearchTerm={setSearchTerm}
        />
        <button
          onClick={handleSearch}
          disabled={loading}
          className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 text-white rounded-lg p-2 ml-2 hover:scale-105 transform transition-transform duration-300 disabled:bg-blue-300"
        >
          {loading ? 'Searching...' : 'Search'}
        </button>
        <button
          onClick={handleHistoryButtonClick}
          className="bg-gray-500 text-white p-2 rounded-lg ml-2"
        >
          <FaHistory />
        </button>
      </div>
  
      {error && <p className="text-red-500 mt-4">{error}</p>}
  
      <div className="flex flex-col w-full max-w-screen-lg mt-4">
        {topItems.map((item, index) => (
          <div key={index} className="mb-6 p-4 bg-tan rounded-lg shadow-lg relative">
            <div className="flex flex-col">
              <div className="flex items-start mb-2">
                <div className="flex-grow">
                  <h2 className="text-2xl font-bold mb-1 text-left">
                    {item.name}
                  </h2>
                  {renderStars(item.stars)}
                  <div className="text-sm text-gray-700 flex items-start mt-2">
                    <div className="mr-4">
                      <p className="mb-1">Pro: {item.pro}</p>
                      <p>Con: {item.con}</p>
                    </div>
                  </div>
                </div>
              </div>
              {descriptions[item.name] ? (
                <p className="text-gray-700">{descriptions[item.name]}</p>
              ) : (
                <div className="flex items-center">
                  <span>Loading description</span>
                  <div className="w-4 h-4 border-t-2 border-blue-600 border-solid rounded-full animate-spin ml-2"></div>
                </div>
              )}
              <div className="absolute top-4 right-4 flex space-x-2">
                {/* Amazon Link */}
                <a
                  href={`https://www.amazon.com/s?k=${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-white shadow-md rounded-lg p-2 hover:scale-105 transition-transform duration-300"
                  style={{ backgroundColor: '#FF9900', color: 'white' }}
                >
                  <FaAmazon className="text-gray-800 text-xl" />
                  <span className="ml-2 text-gray-800">Buy on Amazon</span>
                </a>
  
                {/* eBay Link */}
                <a
                  href={`https://www.ebay.com/sch/i.html?_nkw=${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-white shadow-md rounded-lg p-2 hover:scale-105 transition-transform duration-300"
                  style={{ backgroundColor: '#0064D2', color: 'white' }}
                >
                  <FaEbay className="text-white-800 text-xl" />
                  <span className="ml-2 text-white-800">Buy on eBay</span>
                </a>
  
                {/* Walmart Link */}
                <a
                  href={`https://www.walmart.com/search/?query=${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-white shadow-md rounded-lg p-2 hover:scale-105 transition-transform duration-300"
                  style={{ backgroundColor: '#0071CE' }}
                >
                  <TbBrandWalmart className="text-xl" style={{ color: '#FFD700' }} />
                  <span className="ml-2" style={{ color: '#FFD700' }}>Buy on Walmart</span>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
  
      {/* History Popup */}
      {showHistoryPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-1/2 relative">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-xl font-semibold">Search History</h2>
              <div className="absolute top-2 right-2 flex items-center">
                <button
                  onClick={handleDeleteHistory}
                  className="bg-red-500 text-white p-2 rounded-lg mr-2"
                >
                  <FaTrashAlt />
                </button>
                <button
                  onClick={handleClosePopup}
                  className="text-gray-500"
                >
                  &times;
                </button>
              </div>
            </div>
            {searchHistory.length > 0 ? (
              <ul>
                {searchHistory.map((term, index) => (
                  <li key={index} className="mb-2 cursor-pointer text-blue-600 underline" onClick={() => handleHistoryClick(term)}>
                    {term}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No search history</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
  
};  

export default App;
