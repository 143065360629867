import React, { useState, useEffect } from 'react';
import axios from 'axios';

function SearchBar({ searchTerm, onChange, onKeyPress, handleSearch, setSearchTerm }) {
  const [correctedTerm, setCorrectedTerm] = useState('');
  const debounceDelay = 300; // delay in milliseconds

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setCorrectedTerm('');
      return;
    }

    const handler = setTimeout(() => {
      checkSpelling(searchTerm);
    }, debounceDelay);

    // cleanup function to clear the timeout if the component unmounts or input changes
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const checkSpelling = async (text) => {
    try {
      // const response = await axios.post('http://127.0.0.1:5000/api/spellcheck', { text }); // LOCAL
      const response = await axios.post('https://search.versatileai.net/api/spellcheck', { text }); // PROD
      const correctedWord = response.data.corrected;

      if (correctedWord && correctedWord !== text) {
        setCorrectedTerm(correctedWord);
        setSearchTerm(correctedWord); // Update setSearchTerm with the corrected term
      }
    } catch (error) {
      console.error('Error fetching spell check:', error);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    onChange(e);

    if (!correctedTerm || correctedTerm !== value) {
      setCorrectedTerm(value);
      setSearchTerm(value); // Update setSearchTerm as user types
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(correctedTerm || searchTerm); // Use corrected term or fallback to searchTerm
      handleSearch();
    } else {
      onKeyPress(event);
    }
  };

  return (
    <input
      type="text"
      placeholder="Search..."
      value={correctedTerm}
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      className="border rounded-lg p-2 flex-grow"
      style={{ minWidth: '700px' }}
    />
  );
}

export default SearchBar;
